<template>
  <div>
    <v-dialog v-model="dialogLoad" width="80%" persistent transition="dialog-bottom-transition">
      <s-toolbar close load label="Atención de Pedido" @close="closeSAP($event)" @load="AddSAP($event)" />
      <v-card>
        <v-container>
          <v-row>
            <v-col lg="9">
              <s-toolbar label="Detalle de Pedidos"></s-toolbar>
              <v-data-table hide-default-footer :items-per-page="-1" dense :items="itemsOrder" :headers="config.headers" item-key="DerID">
              </v-data-table>

              <!--  <s-crud noToolbar add-no-function searchInput remove :config="config"
               :filter="filter" @rowSelected="rowSelected($event)"> </s-crud>-->
            </v-col>
            <v-col lg="3">
              <v-card>
                <v-container>
                  <h3 style="text-align: center;">Datos de Usuario</h3>
                  <v-col>
                    <v-row>
                      <v-col class="s-col-text">
                        <s-text label="Despachado por" v-model="UserInfo.NtpFullName" readonly />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-text">
                        <s-text label="Registrado por" v-model="UserInfo.NtpFullName" readonly />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-text">
                        <s-toolbar-person v-model="person" @returnPerson="returnPerson($event)" addBirthDate addTypeSex noEdit autofocus />
                        <s-text label="Recogido por" v-model="getPerson.NtpFullName" readonly />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="s-col-text">
                        <s-textarea label="Comentarios" v-model="RequestComments"></s-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-text">
                        <s-text label="Glosa de Asiento Contable" v-model="RequestAccount" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="processingSAP" :persistent="messageProcessingSAP.length == 0" width="400">
      <v-card color="primary" dark>
        <v-card-text v-if="messageProcessingSAP.length == 0">
          Por favor espere
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
        <v-card-text v-else>
          <div class="text--white pt-4">
            Error al realizar operacion en SAP <br />
            {{ messageProcessingSAP }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _sAttentionDetail from "@/services/Logistics/AttentionDetailService";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
export default {
  components: { sToolbarPerson },
  props: {
    req: null,
    items: null,
    dialogLoad: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    config: {
      service: _sAttentionDetail,
      model: {
        DerID: "ID",
        DerNumLine: "int",
        ArtCode: "string",
        DerDescription: "string",
        UnitMeasurement: "string",
        ItemName: "string",
        Location1: "string",
        Location2: "string",
        DerQuantity: "int",
        SecStatus: 1,
      },
      headers: [
        {
          text: "N°Linea.",
          value: "DerNumLine",
          width: 75,
        },
        {
          text: "Descripcion",
          value: "DerDescription",
          width: 350,
        },
        {
          text: "Ubicacion1",
          value: "Location1",
          width: 90,
        },
        {
          text: "Ubicacion2",
          value: "Location2",
          width: 90,
        },

        {
          text: "Cantidad Atendida",
          value: "DerQuantityServed",
          width: 90,
        },
        {
          text: "Centro Costos",
          value: "CceName",
          width: 200,
          align: "center",
        },
        ,
        {
          text: "Tipo Costo",
          value: "TypeCostName",
          width: 100,
        },
        {
          text: "Linea Negocio",
          value: "BslName",
          width: 200,
          align: "center",
        },
        {
          text: "Proyecto",
          value: "PrjName",
          width: 200,
          align: "center",
        },
        {
          text: "Cuenta Contable",
          value: "ReqAccount",
          align: "center",
          width: 150,
        },
      ],
    },
    selected: {},
    loadingSap: false,
    UserInfo: {},
    person: null,
    getPerson: {
      PrsDocumentNumber: "",
      NtpFullName: null,
    },
    RequestComments: null,
    RequestAccount: null,
    processingSAP: false,
    errorSap: false,
    messageProcessingSAP: "",
  }),
  computed: {
    filter() {
      return { ReqID: this.reqID };
    },
    itemsOrder() {
      return this.items.sort((x, y) => x.DerNumLine - y.DerNumLine);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    rowSelected(item) {
      this.selected = item;
    },
    closeSAP() {
      this.getPerson = {
        PrsDocumentNumber: "",
        NtpFullName: null,
      };
      this.RequestComments = null;
      this.RequestAccount = null;
      this.$emit("close");
    },
    LoadSap() {
      this.loadingSap = true;
    },
    initialize() {
      this.UserInfo = this.$fun.getUserInfo();
    },
    returnPerson(person) {
      if (person != null) {
        this.getPerson.NtpFullName = person.NtpFullName;
        this.getPerson.NtpID = person.NtpID;
        this.getPerson.MevPersonName = person.NtpName;
        this.getPerson.TypePersonDocument = person.TypePersonDocument;
        this.getPerson.PrsDocumentNumber = person.PrsDocumentNumber;
      }
    },
    isValidate(item) {
      let isValid;
      let message;

      isValid = this.$fun.getUserSAPInfo() == null ? false : this.$fun.getUserSAPInfo().UsrID == 0 ? false : true;

      if (!isValid) message = "Credenciales SAP Incorrectas";
      else {
        isValid = item.RequestDispatchedSAP != null;
        if (!isValid) message = "Registre el responsable del Despacho";
        else {
          isValid = item.RequestRegisteredSAP != null;
          if (!isValid) message = "Registre el responsable del Registro";
          else {
            isValid = item.RequestCollectedSAP != null;
            if (!isValid) message = "Registre el responsable del Recojo del requerimiento";
            else {
              isValid = item.RequestComments != null ? (item.RequestComments.length > 5 ? true : false) : false;
              if (!isValid) message = "La descripcion del comentario, debe contener mas caracteres";
              else {
                isValid = item.RequestAccount != null;
                if (!isValid) message = "Debe registrar los datos de la Cuenta Contable";
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    AddSAP() {
      this.messageProcessingSAP = "";
      var obj = {
        ReqID: this.req.ReqID,
        Detail: this.items,
        ReqDocumentNumber: this.req.ReqDocumentNumber,
        UsrCreateID: this.$fun.getUserID(),
        RequestDispatchedSAP: this.UserInfo.PrsDocumentNumber,
        RequestRegisteredSAP: this.UserInfo.PrsDocumentNumber,
        RequestCollectedSAP: this.getPerson.PrsDocumentNumber,
        RequestComments: this.RequestComments,
        RequestAccount: this.RequestAccount,
        NtpDispatchedID: this.UserInfo.NtpID,
        NtpCollectedID: this.getPerson.NtpID,
      };
      console.log(obj);
      console.log(this.getPerson);
      if (this.isValidate(obj))
        this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
          if (val.value) {
            this.processingSAP = true;
            _sAttentionDetail.loadsap(obj, this.$fun.getUserSAPInfo().UsrID).then(
              (r) => {
                if (r.status == 200) {
                  this.processingSAP = false;
                  this.loadingSap = false;
                  this.$fun.alert("Registro correcto", "success");
                  this.$emit("close");
                  _sAttentionDetail.reportpdf(obj, this.$fun.getUserID()).then((r) => {
                    this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
                  });
                }
              },
              (e) => {
                this.errorSap = true;
                this.messageProcessingSAP = e.response.data.Message;
                this.loadingSap = true;
                this.processingSAP = true;
              }
            );
          }
        });
    },
  },
};
</script>
