<template>
  <div>
    <s-crud
      title="Atención de Pedidos"
      excel
      add-no-function
      searchInput
      height="170"
      sortable
      remove
      :config="config"
      :filter="filter"
      @rowSelected="rowSelected($event)"
      ref="refAttendace"
    >
      <template v-slot:options>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" elevation="0" @click="previewSap(true)">
              <v-icon style="font-size:16px;">far fa-arrow-alt-circle-up</v-icon>
            </v-btn>
          </template>
          <span>Subir a SAP</span>
        </v-tooltip>
      </template>

      <template v-slot:filter>
        <v-container>
          <v-col style="">
            <v-row justify="center">
              <v-col lg="3" class="s-col-form">
                <s-select-area label="Filtro por Área" v-model="filter.AreID"> </s-select-area>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date label="Fecha Inicio" v-model="filter.BeginDate"></s-date>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date label="Fecha Fin" v-model="filter.EndDate"></s-date>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </template>
    </s-crud>
    <v-divider></v-divider>

    <v-data-table
      disable-sort
      fixed-header
      :items="items"
      dense
      item-key="DerID"
      :items-per-page="-1"
      :headers="configDetail.headers"
      show-select
      v-model="itemsDetail"
    >
      <template v-slot:item.DerQuantityServed="{ item }">
        <s-text v-model="item.DerQuantityServed" @blur="focusQuantity($event, item)"></s-text>
      </template>
    </v-data-table>

    <s-order-store-detail v-if="reqID > 0" :items="itemsDetail" :req="req" :dialogLoad="dialogLoad" :reqID="reqID" @close="close()" />
  </div>
</template>
<script>
import _sRequirement from "@/services/Logistics/RequirementService";
import _sAttentionDetail from "@/services/Logistics/AttentionDetailService";
import sOrderStoreDetail from "@/views/Logistics/OrderStore/OrderStoreAttentionDetail.vue";
export default {
  components: { sOrderStoreDetail },
  data: () => ({
    req: null,
    itemsDetail: [],
    items: [],
    reqID: 0,
    dialogLoad: false,
    filter: {
      AreID: 0,
      BeginDate: null,
      EndDate: null,
      TypeRequirement: 2,
      ReqStatus: 1,
      CmpID: 0,
    },
    config: {
      service: _sRequirement,
      model: {
        ReqID: "ID",
        ReqDocumentNumber: "string",
        ReqDate: "datetime",
        CmpName: "string",
        UsrName: "string",
        SecStatus: 1,
      },
      headers: [
        {
          text: "Req.",
          value: "ReqDocumentNumber",
          width: 150,
          align: "center",
        },
        {
          text: "Fech.Solicitud",
          value: "ReqDate",
          sortable: true,
          width: 150,
          align: "center",
        },
        {
          text: "Solicitante",
          value: "UsrName",
          width: 150,
          align: "center",
        },
        {
          text: "Área",
          value: "AreName",
          width: 150,
          align: "center",
        },
        {
          text: "Comentario",
          value: "ReqObservation",
        },
      ],
    },
    selected: {},
    loadingSap: false,
    configDetail: {
      service: _sAttentionDetail,
      model: {
        DerID: "ID",
        DerNumLine: "int",
        ArtCode: "string",
        DerDescription: "string",
        UnitMeasurement: "string",
        ItemName: "string",
        Location1: "string",
        Location2: "string",
        DerQuantity: "int",
        SecStatus: 1,
      },
      headers: [
        {
          text: "ArticuloID",
          value: "ArtCode",
          sortable: true,
          width: 80,
        },
        {
          text: "Descripcion",
          value: "DerDescription",
          align: "center",
          width: 380,
        },
        {
          text: "Und.",
          value: "UnitMeasurement",
          align: "end",
        },
        {
          text: "Cantidad",
          value: "DerQuantity",
        },
        {
          text: "Por Atender",
          value: "DerQuantityServed",
        },
        {
          text: "Ubicacion1",
          value: "Location1",
          width: 90,
        },
        {
          text: "Ubicacion2",
          value: "Location2",
          width: 90,
        },
        {
          text: "Centro Costos",
          value: "CceName",
          align: "center",
        },

        {
          text: "Linea Negocio",
          value: "BslName",
        },

        /*  {
          text: "Ubicacion1",
          value: "Location1",
        },
        {
          text: "Ubicacion2",
          value: "Location2",
        },*/
      ],
    },
    filterDetail: {
      ReqID: 0,
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    focusQuantity(event, item) {
      if (item.DerQuantity < item.DerQuantityServed || item.DerQuantityServed <= 0) {
        this.$fun.alert("Cantidad Atendida Incorrecta", "warning");
        item.DerQuantityServed = item.DerQuantity;
        this.items.splice(this.items.indexOf(item), 1, item);
      }
    },

    rowSelected(item) {
      this.selected = item;
      if (item.length > 0) {
        this.reqID = item[0].ReqID;
        this.req = item[0];
      } else this.reqID = 0;

      this.filterDetail.ReqID = this.reqID;
      _sAttentionDetail.pagination({ filter: this.filterDetail }, this.$fun.getUserID()).then((r) => {
        this.items = r.data.data;
      });
    },

    initialize() {
      this.refreshDetail(this.reqID);
    },
    previewSap() {
      if (this.itemsDetail.length <= 0) {
        return;
      }
      this.dialogLoad = true;
    },
    printSap() {
      var obj = {
        ReqID: this.filterDetail.ReqID,
      };

      _sAttentionDetail.reportpdf(obj, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
      });
    },
    close() {
      this.itemsDetail = [];
      this.dialogLoad = false;
      this.$refs.refAttendace.refresh();
    },
    refreshDetail(RequerimentID) {
      this.$nextTick().then(() => {
        if (RequerimentID > 0) this.filterDetail = { ReqID: RequerimentID };
      });
    },
  },
};
</script>
