import Service from "../Service";

const resource = "requirement/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "detailpagination", parameters, {
            params: { requestID: requestID },
        });
    },
    loadsap(obj, requestID) {
        return Service.post(resource + "loadsapoutbound", obj, {
            params: { UssUserCode: requestID },
        });
    },
    reportpdf(obj, requestID) {
        console.log(obj)
        return Service.post(resource + "reportpdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
};